import { Button, Flex, Input, Text } from '@chakra-ui/react';
import SelectInput from 'components/inputs/SelectInput';
import PropTypes from 'prop-types';

const ScenarioSelector = ({
  value,
  options,
  onChange,
  onSave,
  isSaveDisabled,
  comments,
  onCommentsChange,
}) => {
  return (
    <Flex gap={2} align={'center'}>
      <Text>Scenario:</Text>
      <SelectInput
        w={200}
        value={value}
        options={options}
        onChange={onChange}
        placeholder={'-- Select Scenario --'}
      />
      <Text>Comments:</Text>
      <Input
        w={200}
        value={comments || ''}
        onChange={(e) => onCommentsChange(e.target.value)}
      />
      <Button
        w={100}
        variant={'outline'}
        onClick={onSave}
        isDisabled={isSaveDisabled}
      >
        Save
      </Button>
    </Flex>
  );
};

ScenarioSelector.propTypes = {
  value: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  isSaveDisabled: PropTypes.bool,
  comments: PropTypes.string,
  onCommentsChange: PropTypes.func,
};

export default ScenarioSelector;
