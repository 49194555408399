import axios from 'axios';

export const calcCashflowModelApi = async (data) => {
  const response = await axios.post(`/admin/cashflow-model-calc`, data);
  return response.data;
};

export const updateCashflowModelScenarioApi = async ({
  customerId,
  scenarioType,
  settings,
  drivers,
  comments,
}) => {
  const response = await axios.post(
    `/admin/cashflow-model/customer/${customerId}/scenario`,
    { scenarioType, settings, drivers, comments }
  );
  return response.data;
};

export const getCashflowModelScenarioApi = async ({
  customerId,
  scenarioType,
}) => {
  const response = await axios.get(
    `/admin/cashflow-model/customer/${customerId}/scenario`,
    {
      params: {
        type: scenarioType,
      },
    }
  );
  return response.data;
};
